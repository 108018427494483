import React from 'react';
import Layout from '../components/layout';
import { graphql, Link } from 'gatsby';
import * as styles from './poetry.module.css';

const PoetryPage = ({ data }) => {
  const posts = data.allMarkdownRemark ? data.allMarkdownRemark.nodes : [];

  return (
    <Layout>
      <section className={`${styles.jumbo} jumbo h-52 sm:h-72`}>
        <div className="container">
          <h1 className="text-5xl sm:text-8xl text-red-800">Poetry</h1>
          <p>Poetry Catalogue</p>
        </div>
      </section>
      <section className="content">
        <div className="container mx-auto">
          <div className="posts">
            {posts
              .filter(post => new Date(post.frontmatter.date) <= new Date())
              .map((post, index) => {
                const titleWithSpaces = post.frontmatter.title
                  .replace(/_/g, ' ')
                  .toUpperCase();

                const linkName = post.frontmatter.title.replace("'", '');

              return (
                <React.Fragment key={post.id}>
                  <article className="flex flex-col items-center">
                    <Link to={`/poetry/${linkName}`} className="text-center">
                      <h2 className="text-base tracking-[10px]">
                        {titleWithSpaces}
                      </h2>
                    </Link>
                    <p className="text-xs mt-0">{post.frontmatter.date}</p>
                    
                    <div className="relative max-h-24 overflow-hidden">
                      <div
                        className="absolute bottom-0 left-0 w-full h-8 bg-gradient-to-b from-transparent to-white"
                        aria-hidden="true"
                      ></div>
                      <div
                        className="text-base text-gray-800"
                        dangerouslySetInnerHTML={{ __html: post.html }}
                      />
                    </div>

                    {index !== posts.length - 1 && <hr className="w-11/12" />}
                  </article>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </section>
    </Layout>
  );
};

// GraphQL query to fetch poems
export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/poetry/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        id
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
        }
        fields {
          slug
        }
        html
      }
    }
  }
`;

export default PoetryPage;
